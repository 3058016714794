import React from "react";

const Cv = ()=>{
    const docUrl = process.env.PUBLIC_URL + "cv.pdf"
    return (
        <div className='w-full h-[100vh] bg-slate-500'>
            <iframe
                src={docUrl}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                title="CV"
            />
        </div>
    )
}
export default Cv