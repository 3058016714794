
 export const data = [
    {
        "date":"01 June '24",
        "title":"Anime Site with full backend API",
        "thumbnail":"animesite.png",
        "desc":"Fully pledged show streaming website.Has a login system and a media server",
        "techs":[
            "Javascript",
            "JSX",
            "TailwindCSS",
            "NodeJs",
            "Express",
            "MySQL"
        ],
        "url":"https://wunderflix-berfredos-projects.vercel.app/",
    },
    {
        "date":"23 Jan '24",
        "title":"Demeter - Smart GreenHouse",
        "thumbnail":"demeterAuth.jpg",
        "desc":"IoT project with android interface for precision agriculture" +
            ". The greenhouse's internal environment is controlled and maintained at an optimum" +
            " by IoT devices. Management is done remotely through an app. " +
            "The solution is fully scalable and due for precision testing.",
        "techs":[
            "Kotlin",
            "Jetpack Compose",
            "Micropython",
            "RoomDb",
            "LoRa",
            "Firebase-Realtime",
            "Firebase-Firestore",
            "FirebaseAuth",
            "ESP-32"
        ],
        "url":"https://www.muyoma.online",
    },
     {
         "date":"23 Jan '24",
         "title":"AI Tutor",
         "thumbnail":"smartTutorHome.jpg",
         "desc":"An android application that is AI integrated and solely designed" +
             "to make the acquisition of knowledge easier. It allows for parents to" +
             "monitor the progress of their children and set targets accordingly." +
             " The app not only appeals to young students or children but also to graduates and post graduates" +
             "looking to find solutions as fast as they can.",
         "techs":[
             "Kotlin",
             "Jetpack Compose",
             "RoomDb",
             "Firebase-Firestore",
             "FirebaseAuth",
             "Gemini"
         ],
         "url":"https://www.muyoma.online",
     }
]
 export default data