import React from 'react'

const Techtile = ({text}) => {
  return (
    <div className=' m-1 bg-[#041f1e93] w-fit p-2 rounded-xl'>
        <p className='text-slate-500'>{text}</p>
    </div>
  )
}

export default Techtile