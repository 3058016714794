import React from "react";
import Projects from "./Projects";
import { useNavigate } from "react-router-dom";

const Content = () => {
    const navigate = useNavigate();
  return (
    <div className="font-sans lg:ml-[33.3333%] md:ml-[33.3333%] lg:w-2/3 md:w-2/3 sm:w-full lg:p-10 md:p-10 p-3 text-slate-300 leading-5 ">
        <p className='font-extrabold mt-5 mb-5 text-[24px]'>About</p>
        <p className="mb-2 p-2 text-[16px]">
            My passion for software development stems from its blend of creativity,
            problem-solving, and potential for positive impact. It's more than just
            writing code; it's about crafting solutions that transcend boundaries
            and empower communities.
            <br/>
            <button
                onClick={() => {
                    navigate("/cv")
                }}
                className="text-md font-bold mt-3"
            >Read CV</button>
        </p>

        <Projects/>
    </div>
  );
};

export default Content;
