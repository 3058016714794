
import Content from './components/Content';
import Head from './components/Sidebar';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import React from "react";
import Cv from "./components/Cv";

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={
          <div className='lg:flex md:flex'>
            <Head/>
            <Content/>
          </div>
        }/>
        <Route path="/project" element={<h1>Project display page</h1>} />
        <Route path="/cv" element={<Cv/>}/>

          </Routes>
          </Router>
          );
        }

          export default App;
