import React from 'react'
import data from '../data/data.mjs'
import ProjectTile from './ProjectTile'

const Projects = () => {
    
    function show(d,i){
        return(
            <ProjectTile data={d} key={i}/>
        )
    }

  return (
    <div className='mb-5'>
        <p className='font-extrabold mt-5 mb-5 text-[22px]'>Projects</p>
        {
            data.map((d,i) => show(d,i))
        }
    </div>
  )
}

export default Projects